import { jsPDF } from "jspdf";
import "jspdf-autotable";

export const generateNotaDeCobroPDF = (
  baseUrl,
  rowData,
  formatNumber,
  formatNumberUf,
  formatDate,
  mes,
  anio
) => {
  console.log(rowData);
  const pdf = new jsPDF("p", "mm", "a4");

  const marginLeft = 10;
  const marginTop = 10;
  let currentY = marginTop + 20; // Mantendremos el Y actual para ubicar las tablas dinámicamente

  // Datos generales del documento
  const clienteNombre = rowData.nombreCliente;
  const clienteRUT = rowData.rutCliente;
  const folioDocumento = rowData.folio;

  // Fecha de emisión y fecha de vencimiento
  const fechaInicio = formatDate(rowData.inicioPago) || "No disponible";
  const fechaFin = formatDate(rowData.finPago) || "No disponible";

  const fechaEmision = formatDate(rowData.fechaEmision) || "No disponible";
  const fechaVencimiento =
    formatDate(rowData.fechaVencimiento) || "No disponible";
  const valorUf = rowData.valorUf || 0;

  // Lógica para calcular las sumas de cada categoría
  const montosCobro = Array.isArray(rowData.detallesCobro)
    ? rowData.detallesCobro
    : [];

  let totalGastoComun = 0;
  let totalArriendos = 0;
  let totalObrasCiviles = 0;
  let totalServicios = 0;

  montosCobro.forEach((detalle) => {
    const descripcion = detalle.glosa.toLowerCase();
    const total = detalle.unitario * detalle.cantidad;

    if (descripcion.includes("gasto comun")) {
      totalGastoComun += total;
    } else if (descripcion.includes("arriendo")) {
      totalArriendos += total;
    } else if (descripcion.includes("obra civil")) {
      totalObrasCiviles += total;
    } else if (
      ["agua", "electricidad", "internet"].some((servicio) =>
        descripcion.includes(servicio)
      )
    ) {
      totalServicios += total;
    }
  });

  // Información bancaria según rutEmpresa
  let bancoInfo = {};
  if (rowData.rutEmpresa === "76.071.007-5") {
    bancoInfo = {
      banco: "Banco ITAU",
      cuenta: "212099106",
      nombre: "ALTAMIRA S.A.",
      rut: "76.071.007-5",
      tipoCuenta: "Cuenta Corriente",
    };
  } else if (rowData.rutEmpresa === "76.066.122-8") {
    bancoInfo = {
      banco: "BANCO ITAU",
      cuenta: "223286902",
      nombre: "SOC AGRIC E INMOBILIARIA LA SIEMBRA LTDA",
      rut: "76.066.122-8",
      tipoCuenta: "Cuenta Corriente",
    };
  } else {
    bancoInfo = {
      banco: "No disponible",
      cuenta: "No disponible",
      nombre: "No disponible",
      rut: "No disponible",
      tipoCuenta: "No disponible",
    };
  }

  // Logo (si tienes un logo, ajusta la ruta aquí)
  const logoUrl = `${window.location.origin}/logoCBQ.png`;

  pdf.addImage(logoUrl, "PNG", marginLeft, marginTop, 30, 20);

  pdf.setDrawColor(0, 0, 0);
  pdf.rect(marginLeft + 140, marginTop + 8, 50, 12);

  pdf.setFontSize(16);
  pdf.setFont("helvetica", "bold");
  pdf.text("Nota de cobro", marginLeft + 145, marginTop + 7);
  pdf.setFontSize(14);
  pdf.setFont("helvetica", "normal");
  pdf.text(`N°: ${folioDocumento}`, marginLeft + 145, marginTop + 15);

  // Información del cliente y empresa
  pdf.setDrawColor(0, 100, 100);
  pdf.rect(marginLeft, marginTop + 25, 93, 7);
  pdf.rect(marginLeft, marginTop + 33, 93, 7);
  pdf.rect(marginLeft, marginTop + 41, 93, 7);
  pdf.rect(marginLeft, marginTop + 49, 93, 7);

  pdf.rect(marginLeft + 97, marginTop + 25, 93, 7);
  pdf.rect(marginLeft + 97, marginTop + 33, 93, 7);
  pdf.rect(marginLeft + 97, marginTop + 41, 93, 7);
  pdf.rect(marginLeft + 97, marginTop + 49, 93, 7);

  pdf.setFontSize(12);
  pdf.setFont("times", "normal");
  pdf.text(`Srs: ${clienteNombre}`, marginLeft + 3, currentY + 10);
  pdf.text(`RUT: ${clienteRUT}`, marginLeft + 3, currentY + 18);
  pdf.text(
    `Periodo de Cobro: ${mes + "-" + anio}`,
    marginLeft + 3,
    currentY + 26
  );
  pdf.text(
    `Valor UF: $${formatNumber(valorUf)} `,
    marginLeft + 3,
    currentY + 34
  );

  pdf.text(`Inicio Periodo: ${fechaInicio}`, 110, currentY + 10);
  pdf.text(`Fin Periodo: ${fechaFin}`, 110, currentY + 18);
  pdf.text(`Fecha Emisión: ${fechaEmision}`, 110, currentY + 26);
  pdf.text(`Fecha Vencimiento: ${fechaVencimiento}`, 110, currentY + 34);

  currentY += 30;

  // Resumen de pagos
  pdf.setDrawColor(0, 100, 100);
  pdf.setFontSize(14);
  pdf.setFont("times", "normal");
  pdf.text("Resumen de Pagos", marginLeft + 27, currentY + 15);
  pdf.rect(marginLeft, currentY + 17, 93, 45);
  pdf.text("Formas de Pago", marginLeft + 127, currentY + 15);
  pdf.rect(marginLeft + 97, currentY + 17, 93, 45);

  pdf.text("Item", marginLeft + 5, currentY + 22);
  pdf.text("Monto", marginLeft + 70, currentY + 22);
  pdf.rect(marginLeft + 3, currentY + 24, 85, 0.1);

  pdf.setFontSize(11);
  pdf.text("Saldo Anterior", marginLeft + 5, currentY + 28);
  pdf.text("Arriendo(s)", marginLeft + 5, currentY + 33);
  pdf.text("Gasto Común", marginLeft + 5, currentY + 38);
  pdf.text("Servicios", marginLeft + 5, currentY + 43);
  pdf.text("Obras Civiles", marginLeft + 5, currentY + 48);
  pdf.setFontSize(12);
  pdf.text("TOTAL", marginLeft + 5, currentY + 58);

  pdf.text("$ ", marginLeft + 62, currentY + 28);
  pdf.text(
    `$ ${formatNumber(totalArriendos).toLocaleString()}`,
    marginLeft + 62,
    currentY + 33
  );
  pdf.text(
    `$ ${formatNumber(totalGastoComun).toLocaleString()}`,
    marginLeft + 62,
    currentY + 38
  );
  pdf.text(
    `$ ${formatNumber(totalObrasCiviles).toLocaleString()}`,
    marginLeft + 62,
    currentY + 43
  );
  pdf.text(
    `$ ${formatNumber(totalServicios).toLocaleString()}`,
    marginLeft + 62,
    currentY + 48
  );
  pdf.text(
    `$ ${formatNumber(
      totalGastoComun + totalArriendos + totalObrasCiviles + totalServicios
    ).toLocaleString()}`,
    marginLeft + 62,
    currentY + 58
  );

  pdf.setFontSize(12);
  pdf.text("- Depósito", marginLeft + 100, currentY + 22);
  pdf.text("- Transferencia Bancaria", marginLeft + 100, currentY + 27);

  // Calcular el texto para el campo Nombre
  const nombreTexto = `Nombre: ${bancoInfo.nombre}`;
  const nombreDividido = pdf.splitTextToSize(nombreTexto, 85); // Ajusta el ancho máximo aquí (85 en este caso)
  pdf.text(nombreDividido, marginLeft + 102, currentY + 33);

  // Calcular la nueva posición en función de las líneas generadas
  let newY = currentY + 33 + nombreDividido.length * 5; // Ajustar según el tamaño de línea (5 es un ejemplo)

  // Renderizar el resto de los campos dinámicamente
  pdf.text(`Banco: ${bancoInfo.banco}`, marginLeft + 102, newY);
  newY += 5;
  pdf.text(`Rut: ${bancoInfo.rut}`, marginLeft + 102, newY);
  newY += 5;
  pdf.text(`Tipo de Cuenta: ${bancoInfo.tipoCuenta}`, marginLeft + 102, newY);
  newY += 5;
  pdf.text(`N° de Cuenta: ${bancoInfo.cuenta}`, marginLeft + 102, newY);

  currentY += 65; // Ajustamos la posición para las tablas dinámicas

  // Verificamos si detallesCobro existe y es un array
  const detallesCobro = Array.isArray(rowData.detallesCobro)
    ? rowData.detallesCobro
    : [];

  // Generar tabla de Detalle Arriendos y/o Gasto Común si hay datos
  if (
    detallesCobro.filter(
      (detalle) =>
        detalle.glosa.includes("Arriendo") ||
        detalle.glosa.includes("Gasto Común") ||
        detalle.glosa.includes("Gasto Comun")
    ).length > 0
  ) {
    pdf.text("Detalle Arriendos y/o Gasto Común", marginLeft, currentY + 10);
    currentY += 15;
    pdf.autoTable({
      startY: currentY,
      head: [["Item", "Moneda", "Total UF", "Total $"]],
      body: detallesCobro
        .filter(
          (detalle) =>
            detalle.glosa.includes("Gasto Común") ||
            detalle.glosa.includes("Gasto Comun") ||
            detalle.glosa.includes("Arriendo")
        )
        .map((detalle) => [
          detalle.glosa,
          "CLP",
          formatNumberUf(detalle.unitario / valorUf).toLocaleString(),
          formatNumber(detalle.unitario).toLocaleString(),
        ]),
    });
    currentY = pdf.lastAutoTable.finalY + 10; // Ajustamos la posición para la siguiente tabla
  }

  // Generar tabla de Detalle Servicios si hay datos
  if (
    detallesCobro.filter(
      (detalle) =>
        detalle.glosa.includes("Agua") ||
        detalle.glosa.includes("Electricidad") ||
        detalle.glosa.includes("Internet")
    ).length > 0
  ) {
    pdf.text("Detalle Servicios", marginLeft, currentY);
    currentY += 5;
    pdf.autoTable({
      startY: currentY,
      head: [
        [
          "Item",
          "Lect. Inicial",
          "Lect. Final",
          "Consumo",
          "Unidad Medida",
          "Moneda",
          "Total",
        ],
      ],
      body: detallesCobro
        .filter((detalle) =>
          ["Agua", "Electricidad", "Internet"].some((servicio) =>
            detalle.glosa.includes(servicio)
          )
        )
        .map((detalle) => [
          detalle.glosa,
          detalle.lecturaInicial, // No tenemos datos de lecturas, así que ponemos un valor vacío
          detalle.lecturaFinal,
          detalle.cantidad,
          detalle.unidadMedida,
          "CLP",
          formatNumber(detalle.cantidad * detalle.unitario).toLocaleString(),
        ]),
    });
    currentY = pdf.lastAutoTable.finalY + 10; // Ajustamos la posición para la siguiente tabla
  }

  // Generar tabla de Detalle Obras Civiles e Instalaciones si hay datos
  if (
    detallesCobro.filter(
      (detalle) =>
        detalle.glosa.includes("Obra Civil") ||
        detalle.glosa.includes("Habilitación")
    ).length > 0
  ) {
    pdf.text("Detalle Obras Civiles e Instalaciones", marginLeft, currentY);
    currentY += 5;
    pdf.autoTable({
      startY: currentY,
      head: [["Item", "Moneda", "Cuotas", "Total UF", "Total $"]],
      body: detallesCobro
        .filter(
          (detalle) =>
            detalle.glosa.includes("Obra Civil") ||
            detalle.glosa.includes("Habilitacion")
        )
        .map((detalle) => [
          detalle.glosa,
          "CLP",
          "1", // Asumiendo que siempre es una cuota
          formatNumberUf(detalle.unitario / valorUf).toLocaleString(),
          formatNumber(detalle.unitario).toLocaleString(),
        ]),
    });
    currentY = pdf.lastAutoTable.finalY + 10; // Ajustamos la posición para la siguiente tabla
  }

  // Recuadro para Observaciones
  currentY += 10; // Ajustar la posición para que no se sobreponga
  const observacionesHeight = 35;
  pdf.setDrawColor(0, 0, 0);
  pdf.rect(marginLeft, currentY, 190, observacionesHeight);

  // Texto de Observaciones
  pdf.setFontSize(14);
  pdf.text("Observaciones:", marginLeft + 2, currentY + 5);
  pdf.setFontSize(11);
  pdf.text(
    "El cobro del gasto común incluye: \n - Vigilancia perimetral 24/7 \n - Iluminación espacios comunes \n - Mantención, mejoramiento y aseo áreas verdes \n - Mantención, mejoramiento y aseo áreas comúnes",
    marginLeft + 2,
    currentY + 11
  );

  const blob = pdf.output("blob");
  return blob;
};

export const generateEstadoDeCuentaPDF = (
  baseUrl,
  rowData,
  formatNumber,
  formatNumberUf,
  formatDate,
  mes,
  anio
) => {
  const pdf = new jsPDF("p", "mm", "a4");

  const marginLeft = 10;
  const marginTop = 10;

  // Datos básicos
  const clienteNombre = rowData.nombreCliente;
  const numeroEstado = rowData.numeroEstado;
  const clienteRUT = rowData.rutCliente;
  const empresaRUT = rowData.rutEmpresa;
  const inicio = rowData.inicioPago;
  const fin = rowData.finPago;
  const mesAnio = `${mes}-${anio}`;
  console.log(rowData);
  const documentoEjemplo = rowData.documentos[0];
  const fechaEmision = formatDate(documentoEjemplo.fechaEmision);
  const fechaVencimiento = formatDate(documentoEjemplo.fechaVencimiento);
  const valorUf = documentoEjemplo.valorUf || 0;

  // Logo
  const logoUrl = `${window.location.origin}/logoCBQ.png`;

  pdf.addImage(logoUrl, "PNG", marginLeft, marginTop, 30, 20);

  pdf.setDrawColor(0, 0, 0);

  // Recuadro Rut empresa
  pdf.rect(150, marginTop + 5, 50, 15);
  pdf.setFontSize(10);
  pdf.setFont("helvetica", "bold");
  pdf.text(`Rut: ${empresaRUT}`, 152, marginTop + 10);
  pdf.text(`N° Documento: ${numeroEstado}`, 152, marginTop + 17);

  // Título
  pdf.setFontSize(16);
  pdf.setFont("helvetica", "bold");
  pdf.text("Estado de Cuenta", 80, marginTop + 20);

  pdf.rect(marginLeft, marginTop + 30, 90, 7);
  pdf.setFont("helvetica", "normal");
  pdf.setFontSize(11);
  pdf.text(`Srs: ${clienteNombre}`, marginLeft + 2, marginTop + 35);

  pdf.rect(marginLeft, marginTop + 40, 90, 7);
  pdf.text(`RUT: ${clienteRUT}`, marginLeft + 2, marginTop + 45);

  pdf.rect(marginLeft, marginTop + 50, 90, 7);
  pdf.text(`Periodo: ${mesAnio}`, marginLeft + 2, marginTop + 55);

  pdf.rect(marginLeft, marginTop + 60, 90, 7);
  pdf.text(
    `Valor UF: $${formatNumber(valorUf)}`,
    marginLeft + 2,
    marginTop + 65
  );

  // Información recuadro derecha
  pdf.rect(110, marginTop + 30, 90, 7);
  pdf.setFont("helvetica", "normal");
  pdf.setFontSize(11);
  pdf.text(`Inicio Periodo: ${inicio}`, 112, marginTop + 35);

  pdf.rect(110, marginTop + 40, 90, 7);
  pdf.text(`Fin Periodo: ${fin}`, 112, marginTop + 45);

  pdf.rect(110, marginTop + 50, 90, 7);
  pdf.text(`Fecha Emision: ${fechaEmision}`, 112, marginTop + 55);

  pdf.rect(110, marginTop + 60, 90, 7);
  pdf.text(`Fecha Vencimiento: ${fechaVencimiento}`, 112, marginTop + 65);

  pdf.setFontSize(12);
  pdf.rect(marginLeft, marginTop + 78, 90, 45);
  pdf.text("Resumen de pagos", marginLeft, marginTop + 75);

  pdf.rect(110, marginTop + 78, 90, 45);
  pdf.text("Formas de pagos", 110, marginTop + 75);

  const calculateTotalCLP = (unitario, tipoDocumento) =>
    tipoDocumento.toLowerCase() === "factura afecta"
      ? unitario * 1.19
      : unitario;

  const arriendos = rowData.documentos.flatMap((doc) =>
    doc.detallesCobro
      .filter((detalle) => detalle.glosa.toLowerCase().includes("arriendo"))
      .map((detalle) => ({
        ...detalle,
        docCobro: doc.tipoDocumento,
        folio: doc.folio,
        totalCLP: calculateTotalCLP(detalle.unitario, doc.tipoDocumento),
      }))
  );

  const servicios = rowData.documentos.flatMap((doc) =>
    doc.detallesCobro
      .filter((detalle) =>
        ["agua", "electricidad", "internet"].some((serv) =>
          detalle.glosa.toLowerCase().includes(serv)
        )
      )
      .map((detalle) => ({
        ...detalle,
        docCobro: doc.tipoDocumento,
        folio: doc.folio,
        totalCLP: calculateTotalCLP(detalle.unitario, doc.tipoDocumento),
      }))
  );

  const gastoComun = rowData.documentos.flatMap((doc) =>
    doc.detallesCobro
      .filter((detalle) => detalle.glosa.toLowerCase().includes("gasto comun"))
      .map((detalle) => ({
        ...detalle,
        docCobro: doc.tipoDocumento,
        folio: doc.folio,
        totalCLP: calculateTotalCLP(detalle.unitario, doc.tipoDocumento),
      }))
  );

  const obrasCiviles = rowData.documentos.flatMap((doc) =>
    doc.detallesCobro
      .filter((detalle) =>
        ["obra civil", "habilitacion"].some((keyword) =>
          detalle.glosa.toLowerCase().includes(keyword)
        )
      )
      .map((detalle) => ({
        ...detalle,
        docCobro: doc.tipoDocumento,
        folio: doc.folio,
        totalCLP: calculateTotalCLP(detalle.unitario, doc.tipoDocumento),
      }))
  );

  // Calcular total de IVA para todas las categorías
  const totalIVAArriendos = arriendos.reduce(
    (acc, detalle) =>
      detalle.docCobro.toLowerCase() === "factura afecta"
        ? acc + detalle.unitario * 0.19
        : acc,
    0
  );

  const totalIVAServicios = servicios.reduce(
    (acc, detalle) =>
      detalle.docCobro.toLowerCase() === "factura afecta"
        ? acc + detalle.unitario * 0.19
        : acc,
    0
  );

  const totalIVAGastoComun = gastoComun.reduce(
    (acc, detalle) =>
      detalle.docCobro.toLowerCase() === "factura afecta"
        ? acc + detalle.unitario * 0.19
        : acc,
    0
  );

  const totalIVAObrasCiviles = obrasCiviles.reduce(
    (acc, detalle) =>
      detalle.docCobro.toLowerCase() === "factura afecta"
        ? acc + detalle.unitario * 0.19
        : acc,
    0
  );

  // Total general del IVA
  const totalIVA =
    totalIVAArriendos +
    totalIVAServicios +
    totalIVAGastoComun +
    totalIVAObrasCiviles;

  // Función para poblar la tabla en el PDF
  const populateTable = (columns, data, startY, title) => {
    pdf.text(title, marginLeft, startY - 2);
    pdf.autoTable({
      startY: startY,
      head: [columns],
      body: data.length ? data : [["NO APLICA"]],
      styles: { halign: "center", valign: "middle" },
      headStyles: { fillColor: [200, 200, 200] },
    });
    return pdf.autoTable.previous.finalY + 10; // Espacio para la siguiente tabla
  };

  const totalArriendos = arriendos.reduce(
    (acc, arriendo) => acc + arriendo.unitario * arriendo.cantidad,
    0
  );
  const totalServicios = servicios.reduce(
    (acc, serv) => acc + serv.unitario * serv.cantidad,
    0
  );
  const totalGastoComun = gastoComun.reduce(
    (acc, gasto) => acc + gasto.unitario * gasto.cantidad,
    0
  );
  const totalObrasCiviles = obrasCiviles.reduce(
    (acc, obra) => acc + obra.unitario * obra.cantidad,
    0
  );
  const totalPagar =
    totalArriendos + totalServicios + totalGastoComun + totalObrasCiviles;

  pdf.setFontSize(11);
  pdf.text("Item", marginLeft + 5, marginTop + 83);
  pdf.text(" Neto", marginLeft + 55, marginTop + 83);
  pdf.rect(marginLeft + 3, marginTop + 84, 85, 0.1);

  pdf.text("Saldo Anterior", marginLeft + 5, marginTop + 89);
  pdf.text("Arriendo(s)", marginLeft + 5, marginTop + 94);
  pdf.text("Gasto Común", marginLeft + 5, marginTop + 99);
  pdf.text("Servicios", marginLeft + 5, marginTop + 104);
  pdf.text("Obras Civiles", marginLeft + 5, marginTop + 109);
  pdf.setFontSize(12);
  pdf.text("TOTAL", marginLeft + 5, marginTop + 116);
  pdf.text("IVA", marginLeft + 5, marginTop + 121); // Agregar IVA al resumen

  pdf.setFontSize(11);
  pdf.text(
    `$ ${formatNumber(totalArriendos)}`,
    marginLeft + 55,
    marginTop + 94
  );
  pdf.text(
    `$ ${formatNumber(totalGastoComun)}`,
    marginLeft + 55,
    marginTop + 99
  );
  pdf.text(
    `$ ${formatNumber(totalServicios)}`,
    marginLeft + 55,
    marginTop + 104
  );
  pdf.text(
    `$ ${formatNumber(totalObrasCiviles)}`,
    marginLeft + 55,
    marginTop + 109
  );
  pdf.setFontSize(12);
  pdf.text(`$ ${formatNumber(totalPagar)}`, marginLeft + 55, marginTop + 116);
  pdf.text(`$ ${formatNumber(totalIVA)}`, marginLeft + 55, marginTop + 121);

  // Seleccionar información bancaria basada en rutEmpresa
  let bancoInfo = {};
  if (rowData.rutEmpresa === "76.071.007-5") {
    bancoInfo = {
      banco: "Banco ITAU",
      cuenta: "212099106",
      nombre: "ALTAMIRA S.A.",
      rut: "76.071.007-5",
      tipoCuenta: "Cuenta Corriente",
    };
  } else if (rowData.rutEmpresa === "76.066.122-8") {
    bancoInfo = {
      banco: "BANCO ITAU",
      cuenta: "223286902",
      nombre: "SOC AGRIC E INMOBILIARIA LA SIEMBRA LTDA",
      rut: "76.066.122-8",
      tipoCuenta: "Cuenta Corriente",
    };
  } else {
    bancoInfo = {
      banco: "No disponible",
      cuenta: "No disponible",
      nombre: "No disponible",
      rut: "No disponible",
      tipoCuenta: "No disponible",
    };
  }

  pdf.setFontSize(12);
  pdf.text("- Depósito", 112, marginTop + 83);
  pdf.text("- Transferencia Bancaria", 112, marginTop + 88);

  // Banco
  const bancoTexto = `Banco: ${bancoInfo.banco}`;
  const bancoDividido = pdf.splitTextToSize(bancoTexto, 85); // Ajustar ancho según espacio disponible
  pdf.text(bancoDividido, 112, marginTop + 95);

  // Calcular nueva posición en función de las líneas del texto anterior
  let currentY = marginTop + 95 + bancoDividido.length * 5;

  // Cuenta
  const cuentaTexto = `Cuenta: ${bancoInfo.cuenta}`;
  const cuentaDividido = pdf.splitTextToSize(cuentaTexto, 85);
  pdf.text(cuentaDividido, 112, currentY);
  currentY += cuentaDividido.length * 5;

  // Nombre
  const nombreTexto = `Nombre: ${bancoInfo.nombre}`;
  const nombreDividido = pdf.splitTextToSize(nombreTexto, 85);
  pdf.text(nombreDividido, 112, currentY);
  currentY += nombreDividido.length * 5;

  // RUT
  const rutTexto = `RUT: ${bancoInfo.rut}`;
  const rutDividido = pdf.splitTextToSize(rutTexto, 85);
  pdf.text(rutDividido, 112, currentY);
  currentY += rutDividido.length * 5;

  // Tipo de Cuenta
  const tipoCuentaTexto = `Tipo de Cuenta: ${bancoInfo.tipoCuenta}`;
  const tipoCuentaDividido = pdf.splitTextToSize(tipoCuentaTexto, 85);
  pdf.text(tipoCuentaDividido, 112, currentY);

  // Columnas y datos para la tabla de Arriendos
  const arriendoColumns = [
    "ITEM",
    "DOC. COBRO",
    "FOLIO",
    "NETO",
    "IVA",
    "TOTAL UF",
    "TOTAL CLP",
  ];
  const arriendoData = arriendos.map((detalle) => [
    detalle.glosa,
    detalle.docCobro,
    detalle.folio,
    formatNumber(detalle.unitario), // NETO
    detalle.docCobro.toLowerCase() === "factura afecta"
      ? formatNumber(detalle.unitario * 0.19) // IVA 19%
      : "N/A", // No aplica IVA
    formatNumberUf(detalle.totalCLP / valorUf), // TOTAL UF
    formatNumber(detalle.totalCLP), // TOTAL CLP con IVA si aplica
  ]);

  // Columnas y datos para la tabla de Servicios
  const servicioColumns = [
    "Servicio",
    "Lectura Inicial",
    "Lectura Final",
    "Consumo",
    "Unidad",
    "DOC. COBRO",
    "FOLIO",
    "NETO",
    "IVA",
    "Total",
  ];
  const servicioData = servicios.map((detalle) => {
    const neto = detalle.unitario * detalle.cantidad; // Neto calculado como consumo por valor unitario
    const iva =
      detalle.docCobro.toLowerCase() === "factura afecta"
        ? neto * 0.19 // IVA 19% si aplica
        : 0;
    const totalCLP = neto + iva; // Total CLP basado en el neto más el IVA si aplica

    return [
      detalle.glosa,
      detalle.lecturaInicial || "N/A",
      detalle.lecturaFinal || "N/A",
      detalle.cantidad,
      detalle.unidadMedida,
      detalle.docCobro,
      detalle.folio,
      formatNumber(neto), // Monto neto
      detalle.docCobro.toLowerCase() === "factura afecta"
        ? formatNumber(iva) // IVA 19%
        : "N/A",
      formatNumber(totalCLP), // Total CLP correcto
    ];
  });

  const gastoComunColumns = [
    "Item",
    "DOC. COBRO",
    "FOLIO",
    "NETO",
    "IVA",
    "Total",
  ];
  const gastoComunData = gastoComun.map((detalle) => [
    detalle.glosa,
    detalle.docCobro,
    detalle.folio,
    formatNumber(detalle.unitario), // NETO
    detalle.docCobro.toLowerCase() === "factura afecta"
      ? formatNumber(detalle.unitario * 0.19) // IVA 19%
      : "N/A", // No aplica IVA
    formatNumber(detalle.totalCLP),
  ]);

  const obraCivilColumns = [
    "Item",
    "DOC. COBRO",
    "FOLIO",
    "NETO",
    "IVA",
    "Total",
  ];
  const obraCivilData = obrasCiviles.map((detalle) => [
    detalle.glosa,
    detalle.docCobro,
    detalle.folio,
    formatNumber(detalle.unitario), // NETO
    detalle.docCobro.toLowerCase() === "factura afecta"
      ? formatNumber(detalle.unitario * 0.19) // IVA 19%
      : "N/A", // No aplica IVA
    formatNumber(detalle.totalCLP),
  ]);

  // Agregar tablas al PDF
  currentY = marginTop + 135;

  currentY = populateTable(
    arriendoColumns,
    arriendoData,
    currentY,
    "Detalles Arriendos"
  );
  currentY = populateTable(
    servicioColumns,
    servicioData,
    currentY,
    "Detalles Servicios"
  );
  currentY = populateTable(
    gastoComunColumns,
    gastoComunData,
    currentY,
    "Detalles Gasto Común"
  );
  currentY = populateTable(
    obraCivilColumns,
    obraCivilData,
    currentY,
    "Detalles Obras Civiles e Instalaciones"
  );

  // Posición inicial para las notas
  let finalY = pdf.lastAutoTable ? pdf.lastAutoTable.finalY + 10 : 270;

  // Espacio disponible en la página actual
  const pageHeight = pdf.internal.pageSize.height;
  const marginBottom = 20; // Margen inferior para evitar que el contenido toque el borde

  // Verifica si hay espacio suficiente para las notas
  if (finalY + 40 > pageHeight - marginBottom) {
    pdf.addPage(); // Añade una nueva página si el contenido excede el espacio disponible
    finalY = 20; // Reinicia la posición Y al inicio de la nueva página
  }

  // Renderiza el recuadro de las notas
  pdf.setDrawColor(0, 0, 0);
  pdf.rect(10, finalY, 190, 30); // Dibuja un rectángulo para "NOTAS" con ancho 190 y alto 30

  // Agrega el contenido de las notas
  pdf.setFontSize(10);
  pdf.setFont("helvetica", "normal");
  pdf.text("NOTAS", 12, finalY + 6);

  const notas = [
    "1.- Los documentos tributarios se harán llegar a usted en forma separada a este resumen.",
    "2.- El valor de la UF corresponde al primer día hábil del mes de emisión de este Estado de Cuenta.",
    "3.- La fecha límite para su pago es el 5to día hábil del mes.",
    "4.- Estamos atentos a atenderlos a través del teléfono XXXXXXXXXX o mail XXXXX@XXXXX.cl",
  ];

  let offsetY = finalY + 12; // Posición inicial del texto de notas

  notas.forEach((line) => {
    pdf.text(line, 12, offsetY); // Posición de cada línea en X=12
    offsetY += 5; // Espacio entre líneas
  });

  return pdf.output("blob");
};
